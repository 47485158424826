html,
body,
#root,
.App {
  margin: 0;
  padding: 0;

  height: 100%;
}

.content {
  width: 80%;
  height: 100%;
  margin-top: 2em;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  user-select: none;
}

.instructions {
  padding-left: 1em;
}

.signature-canvas-wrapper {
  width: 100%;
  height: 80%;
  max-height: 300px;
  margin-bottom: 2em;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  touch-action: none;
}

.signature-canvas {
  position: absolute;
  background-color: lightgray;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.bottom-bar {
  position: fixed;
  background-color: rgb(232, 232, 232);
  box-shadow: -2px 0 9px rgb(0 0 0 / 29%);
  left: 0;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 1em 2em;
  display: flex;
  justify-content: end;
}

.test-name {
  margin-left: 1em;
  margin-right: 1em;
}
